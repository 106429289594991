<template>
  <ListaPPDs/>
</template>

<script>
import ListaPPDs from "@/components/ppd/ListaPPDs"; // @ is an alias to /src
export default {
    components: {
        ListaPPDs
    }
}
</script>